import { useFirebaseAuth } from '@flock/utils'
import { confirmPasswordReset } from 'firebase/auth'
import React, { ReactNode, useEffect, useState } from 'react'
import { TrackedLink } from '../TrackedLink'
import {
  PasswordResetFormProps,
  PasswordResetFormPresentationalProps,
  FormResult,
} from './passwordResetFormTypes'

const usePasswordResetForm: (
  props: PasswordResetFormProps
) => PasswordResetFormPresentationalProps = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ReactNode>('')
  const [code, setCode] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [showContactFlock, setShowContactFlock] = useState(false)
  const [hideActionButtons, setHideActionButtons] = useState(false)

  const { auth } = useFirebaseAuth()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const newCode = urlParams.get('oobCode')
    const action = urlParams.get('mode')

    if (action === 'revertSecondFactorAddition' || action === 'recoverEmail') {
      if (typeof window !== 'undefined') {
        if (window.location.href.includes('overmoon')) {
          setHideActionButtons(true)
        }
      }

      setShowContactFlock(true)
      return
    }

    if (newCode) {
      setCode(newCode)
    } else {
      window.location.href = '/login'
    }
  }, [])

  const onResetPassword = async ({ password }: FormResult) => {
    setLoading(true)
    try {
      await confirmPasswordReset(auth, code, password)
      setLoading(false)
      setShowSuccess(true)
      setTimeout(() => {
        window.location.href = '/login'
      }, 5000)
    } catch (e: any) {
      setLoading(false)
      if (
        (e.message && e.message.includes('user-not-found')) ||
        e.message.includes('wrong-password')
      ) {
        setError('Invalid email or password')
      } else {
        setError(
          <span>
            This password reset link may be expired. Please request a new
            password reset link at{' '}
            <TrackedLink
              to="https://investor.flockhomes.com/send-password-reset"
              color="error"
              sx={{
                color: '#891A1A',
                textDecoration: 'underline',
              }}
            >
              investor.flockhomes.com/send-password-reset
            </TrackedLink>
            . If the issue continues, please contact us.
          </span>
        )
      }
    }
  }

  return {
    loading,
    error,
    code,
    showSuccess,
    onResetPassword,
    showContactFlock,
    hideActionButtons,
  }
}

export default usePasswordResetForm
