import React from 'react'
import * as Yup from 'yup'
import { Box, CircularProgress, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import usePasswordResetForm from './usePasswordResetForm'
import { PasswordResetFormProps } from './passwordResetFormTypes'
import { InputType } from '../GridForm/InputTypes'
import GridForm from '../GridForm/GridForm'
import flockTheme from '../../theme/theme'
import { TrackedButton } from '../TrackedButton'
import { INVESTOR_EMAIL, PHONE_URL } from '../../constants'

const formSchema = Yup.object().shape({
  // Password must contain at least one special character (!@#$%^&*), lowercase, uppercase, and number
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
      'Password must contain at least one special character, lowercase, uppercase, and number'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

const PasswordResetForm = (props: PasswordResetFormProps) => {
  const {
    loading,
    error,
    showSuccess,
    onResetPassword,
    showContactFlock,
    hideActionButtons,
  } = usePasswordResetForm(props)

  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: flockTheme.palette.green0.main,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="16px"
        maxWidth="400px"
        gap="16px"
      >
        {showSuccess ? (
          <>
            <Typography variant="h3">
              Password reset successful! You will be redirected shortly...
            </Typography>
            <CircularProgress />
          </>
        ) : (
          <>
            {showContactFlock ? (
              <>
                <Typography variant="h3">
                  {' '}
                  Please contact us for issues related to your email or
                  multi-factor authentication.
                </Typography>
                {!hideActionButtons && (
                  <Box display="flex" gap="16px">
                    <TrackedButton
                      size="smallForm"
                      onClick={() => {
                        window.open(PHONE_URL)
                      }}
                    >
                      Call
                    </TrackedButton>
                    <TrackedButton
                      size="smallForm"
                      onClick={() => window.open(`mailto:${INVESTOR_EMAIL}`)}
                    >
                      Email
                    </TrackedButton>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Typography variant="h3">Confirm new password</Typography>
                <GridForm
                  loading={loading}
                  onSubmit={onResetPassword}
                  gridProps={{
                    gap: '16px',
                  }}
                  formProps={{
                    resolver: yupResolver(formSchema),
                  }}
                  inputConfigs={[
                    {
                      name: 'password',
                      type: InputType.Text,
                      required: true,
                      props: {
                        label: 'Password',
                        type: 'password',
                        fullWidth: true,
                      },
                    },
                    {
                      name: 'confirmPassword',
                      type: InputType.Text,
                      required: true,
                      props: {
                        label: 'Confirm Password',
                        type: 'password',
                        fullWidth: true,
                      },
                    },
                    {
                      name: 'errorMsg',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <>
                            {error && (
                              <Typography
                                sx={{ fontFamily: 'Outfit' }}
                                variant="body2"
                                color="error"
                              >
                                {error}
                              </Typography>
                            )}
                          </>
                        ),
                      },
                    },
                  ]}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default PasswordResetForm
